<template>
  <!-- ==============================================
  **Register**
  =================================================== -->
  <section class="login-outer">
    <div class="content-area">
      <div class="login-form-holder">
        <div class="inner">
          <div class="login-form">

            <div class="row mb-2">
              <router-link class="navbar-brand mx-auto d-block" :to="{ name: 'home' }">
                <img :src="`${baseURL}/assets/images/logo-sm.png`" class="img-fluid" alt="">
              </router-link>
            </div>

            <h3>Register Now</h3>
            <Form @submit="trySignup" :validation-schema="signupFromValidationSchema">
              <div class="mail">
                <label>Email Address</label>
                <Field name="Email" v-model="loginModel.Email" placeholder="" type="text" />
                <ErrorMessage class="text-danger" name="Email" />
              </div>
              <div class="password">
                <label>Password</label>
                <Field name="Password" v-model="loginModel.Password" placeholder="" type="password" />
                <ErrorMessage class="text-danger" name="Password" />
              </div>
              <div class="confirm-password">
                <label>Confirm Password</label>
                <Field name="ConfirmPassword" placeholder="" type="password"/>
                <ErrorMessage class="text-danger" name="ConfirmPassword" />
              </div>
              <div class="terms">
                <label>
                  <input value="" type="checkbox">
                  <span>I agree to all <a href="#">terms</a></span></label>
              </div>
              <div class="login-btn-sec">
                <button class=" btn login-btn">Register</button>
              </div>
            </form>
          </div>
        </div>
        <div class="box-hav-accnt">
          <p>Already have an account? <router-link :to="{ name: 'login' }">Sign in</router-link></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as yup from 'yup'

import LoginModel from '../models/LoginModel'
import SignupService from '../services/signup.service'

const signupFromValidationSchema = yup.object({
  Email: yup.string('').nullable().required().email().label('Email Address'),
  Password: yup.string('').nullable()
    .required()
    .label('Password')
    .min(8)
    .max(12)
    .matches(/^[a-zA-Z0-9]/, 'Password should be alphanumeric only.'),
  ConfirmPassword: yup.string('').nullable()
    .required()
    .label('Confirm Password')
    .min(8)
    .max(12)
    .matches(/^[a-zA-Z0-9]/, 'Password should be alphanumeric only.')
    .oneOf([yup.ref('Password'), null], 'Passwords must match')
})

export default {
  name: 'SignupPage',
  data () {
    return {
      signupFromValidationSchema,
      baseURL: process.env.VUE_APP_BASE_URL,
      loginModel: new LoginModel()
    }
  },
  mounted () {
    //
  },
  methods: {
    trySignup: function () {
      SignupService.signupUser(this.loginModel).then(
        () => {
          this.$router.push({ name: 'dashboard' })
        }
      )
    }
  }
}
</script>

import Config from './config'
var api = Config.api

const signupAPIRoot = api.BASE_URL + '/signup'
const signupAPI = signupAPIRoot
const emailVerificationAPI = signupAPIRoot + '/verify-email'

class SignupService {
  signupUser (signupModel) {
    return api.postCall(signupAPI, signupModel, api.ShowAlert, api.ShowAlert)
  }

  verifyEmail (encryptedURL) {
    return api.postCall(emailVerificationAPI, { encryptedURL: encryptedURL }, api.ShowAlert, api.ShowAlert)
  }
}
export default new SignupService()

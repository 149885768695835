export default class LoginModel {
  constructor () {
    this.Email = null
    this.Password = null
  }

  load (data) {
    this.Email = data.Email
    this.Password = data.Password
  }
}
